<template>
  <div class="manage">
    <!-- 搜索区域 -->
    <McProject ref="project"></McProject>
    <div class="manage-header" style="justify-content: flex-start">
    <el-button
        size="mini"
        class="el-icon-download"
        type="info"
        plain
        @click="downloadClick">下载模版</el-button>
      <el-upload class="zuobianju"
                 action="#"
                 :show-file-list="false"
                 :http-request="importClick"
                 size="mini">
        <el-button size="mini" class="el-icon-upload" type="info" plain>导入文件</el-button>
      </el-upload>
      <el-button style="margin-left: 10px"
                 @click="submitClick"
                 size="mini"
                 type="primary">提交</el-button>
    </div>

    <div class="center-container">
      <!-- 表格 -->
      <el-table
        :data="List"
        style="width: 80%;"
        border
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column prop="Domain" label="官网"/>
        <el-table-column prop="SubmissionDeadline" label="官网"/>
        <el-table-column prop="ConferenceDate" label="官网"/>
        <el-table-column prop="RegistrationDeadline" label="邮箱"/>
      </el-table>

      <el-input v-model="message" style="width: 20%;" type="textarea" :autosize="{ minRows: 30 }"></el-input>
    </div>
  </div>
</template>

<script>
import '@/assets/static/css/base.css'
import Service from '@/service/Business/DateService'

export default Service
</script>

<style lang="less" scoped>
.zuobianju {
  margin-left: 10px;
}

.center-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>
