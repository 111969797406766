import BaseController from '@/service/BaseService'
import ToolDateRequest from '@/request/Business/ToolDateRequest'
import { errorTip } from '@/utils/Tip'
import McProject from '@/components/McProject.vue'
import { cdnUrl } from '../../../config'

const base = new BaseController()

export default {
  components: { McProject },
  data () {
    return {
      List: [],
      message: '',
      fullscreenLoading: false
    }
  },
  // 初始化函数
  created: async function () {
    await base.init(this, new ToolDateRequest())
  },
  methods: {
    // 官网导入
    submitClick: async function () {
      const webSite = this.List
      if (webSite.length === 0) errorTip(this, '未导入网站')
      this.$refs.project.init(webSite.length)
      const messages = []
      for (let i = 0; i < webSite.length; i++) {
        await new Promise((resolve) => {
          setTimeout(async () => {
            try {
              const result = await base.request.submitPost(webSite[i])
              messages.push(result.message)
              this.$refs.project.setInc()
            } catch (error) {
              messages.push('请求失败')
            }
            resolve()
          }, 1000) // 设置延迟时间
        })
      }
      this.message = messages.join('\r\n')
    },
    // 导入事件
    importClick: async function ({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      const result = await base.request.importPost(formData)
      this.List = result.data
    },
    downloadClick: async function () {
      try {
        const response = await fetch(`${cdnUrl}/download/date.xlsx`);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'date.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('下载文件时出错:', error);
      }
    }
  }
}
